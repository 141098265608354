import React from 'react';
import dois from './imgs/ligacao2.png';
import tres from './imgs/ligacao3.png';
import quatro from './imgs/ligacao4.png';

export default class Experiencia extends React.Component {
    render() {
        return (
            <div id="segunda">
                <img src={dois} id="ligacaoDois" />

                <div id="primExp">
                    <h2>2018</h2>
                    <h3>Estágio de Verão</h3>
                    <p>Ubiwhere</p>
                    <p className="cinzento">Estágio de Verão na área de Front-End Developer no qual desenvolvi um simulador de sofás que permite ao utilizador visualizar os modelos dos sofás em 3D e personalizá-los para posterior encomenda. Tecnologias utilizadas: React.js e Three.js.</p>
                </div>

                <img src={tres} id="ligacaoTres" />

                <div id="segExp">
                    <h2>2016</h2>
                    <h3>Formação em Contexto de Trabalho</h3>
                    <p>Copek Design</p>
                    <p className="cinzento">Estágio curricular no qual desenhei a interface e implementei dois websites em wordpress, que foram o da empresa e da banda bLUNDER.</p>
                </div>

                <img src={quatro} id="ligacaoQuatro" />

                <div id="terExp">
                    <h2>2014</h2>
                    <h3>Estágio de Verão</h3>
                    <p>Lacovale, Lda</p>
                    <p className="cinzento">Estágio de Verão na área de gestão, no qual arquivei e criei faturas de clientes e fornecedores e também inseri clientes e fornecedores na nova plataforma de gestão da empresa.</p>
                </div>
            </div>
        )
    }
}