import React from 'react';
import './App.css';
import header from './imgs/header.gif';
import Icones from './icones';
import Sobre from './sobre';
import Experiencia from './experiencia';
import Educacao from './educacao';
import Competencias from './competencias';
import Projetos from './projetos';
import Footer from './footer';
import cv from './imgs/cv.pdf';

function App() {
  return (
    <div className="App">
      <img src={header} id="header" />

      <Icones />

      <div id="content">
        <Sobre />
        <Experiencia />
        <Educacao />
        <Competencias />
        <Projetos />

        <a href={cv} download><button id="download">Download CV</button></a>
      </div>

      <Footer />
    </div>
  );
}

export default App;