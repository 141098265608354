import React from 'react';
import cinco from './imgs/ligacao5.png';
import seis from './imgs/ligacao6.png';
import chapeu from './imgs/icones-14.png';

export default class Educacao extends React.Component {
    render() {
        return (
            <div id="terceira">
                <div id="tituloEduc">
                    <img src={cinco} id="ligacaoCinco" />
                    <img src={chapeu} id="chapeu" />
                    <h1 id="educ">Educação</h1>
                </div>

                <img src={seis} id="ligacaoSeis" />

                <div id="primEdu">
                    <h2>2016-2019</h2>
                    <p>Licenciatura em Novas Tecnologias da Comunicação</p>
                    <p className="cinzento">Universidade de Aveiro</p>
                </div>

                <div id="segEdu">
                    <h2>2013-2016</h2>
                    <p>Design de Comunicação, especialização em Multimédia</p>
                    <p className="cinzento">Escola Artística Soares dos Reis, Porto</p>
                </div>
            </div>
        )
    }
}
