import React from 'react';
import icone1 from './imgs/icones-01.png';
import icone2 from './imgs/icones-02.png';
import icone3 from './imgs/icones-03.png';

export default class Icones extends React.Component {
    render() {
        return (
            <div id="icones">
                <a href="https://www.linkedin.com/in/sofiaasribeiro/" target="_blank">
                    <img src={icone1} className="icone" id="ic1" />
                </a>
                
                <a href="https://www.behance.net/_sofiaribeiro" target="_blank">
                    <img src={icone2} className="icone" id="ic2" />
                </a>
                
                <a href="https://github.com/soalribeiro" target="_blank">
                    <img src={icone3} className="icone" id="ic3" />
                </a>
            </div>
        )
    }
}