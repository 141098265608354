import React from 'react';
import comp from './imgs/competencia.png';
import { Row, Col } from 'reactstrap';

export default class Competencias extends React.Component {
    constructor() {
        super();
        this.state = {
            width: window.innerWidth
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    render() {
        const { width } = this.state;
        const isMobile = width >= 715;
        const isMobile2 = width < 715 && width > 425;

        if (isMobile) {
            return (
                <div id="quarta">
                    <div id="compDiv">
                        <img src={comp} id="compIcone" />
                        <h1 id="comp">Competências</h1>
                    </div>

                    <Row id="competencias">
                        {/* Programação */}
                        <Col xs="6" className="colComp">
                            <Row>
                                <Col xs="6">
                                    <h2>Programação</h2>
                                    <p>HTML</p>
                                    <p>CSS</p>
                                    <p>JavaScript</p>
                                    <p>React.js</p>
                                    <p>React Native</p>
                                    <p>jQuery</p>
                                    <p>Three.js</p>
                                    <p>Node.js</p>
                                    <p>PHP</p>
                                </Col>
                                <Col xs="6" id="prog2">
                                    <p>MySQL</p>
                                    <p>BootStrap</p>
                                    <p>Wordpress</p>
                                    <p>Git</p>
                                </Col>
                            </Row>
                        </Col>

                        {/* Software */}
                        <Col xs="6" className="colComp" id="soft">
                            <h2>Software</h2>
                            <p>Adobe Xd</p>
                            <p>Adobe Illustrator</p>
                            <p>Adobe InDesign</p>
                            <p>Adobe Photoshop</p>
                            <p>Adobe Photoshop Lightroom</p>
                            <p>Adobe Premiere</p>
                            <p>Adobe After Effects</p>
                            <p>Adobe Animate</p>
                            <p>3ds Max</p>
                        </Col>
                    </Row>
                </div>
            )
        } else if (isMobile2) {
            return (
                <div id="quarta">
                    <div id="compDiv">
                        <img src={comp} id="compIcone" />
                        <h1 id="comp">Competências</h1>
                    </div>

                    <Row id="competencias">
                        {/* Programação */}
                        <Col xs="6" className="colComp">
                            <Row>
                                <Col xs="6">
                                    <h2>Programação</h2>
                                    <p>HTML</p>
                                    <p>CSS</p>
                                    <p>JavaScript</p>
                                    <p>React.js</p>
                                    <p>React Native</p>
                                    <p>jQuery</p>
                                    <p>Three.js</p>
                                </Col>
                                <Col xs="6" id="prog2">
                                    <p>Node.js</p>
                                    <p>PHP</p>
                                    <p>MySQL</p>
                                    <p>BootStrap</p>
                                    <p>Wordpress</p>
                                    <p>Git</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* Software */}
                        <Col xs="6" className="colComp" id="soft">
                            <Row>
                                <Col xs="6">
                                    <h2>Software</h2>
                                    <p>Adobe Xd</p>
                                    <p>Adobe Illustrator</p>
                                    <p>Adobe InDesign</p>
                                    <p>Adobe Photoshop</p>
                                    <p>Adobe Photoshop Lightroom</p>
                                </Col>
                                <Col xs="6" id="prog2">
                                    <p>Adobe Premiere</p>
                                    <p>Adobe After Effects</p>
                                    <p>Adobe Animate</p>
                                    <p>3ds Max</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )
        } else if (width <= 425) {
            return (
                <div id="quarta">
                    <div id="compDiv">
                        <img src={comp} id="compIcone" />
                        <h1 id="comp">Competências</h1>
                    </div>

                    <Row id="competencias">
                        {/* Programação */}
                        <Col xs="6" className="colComp">
                            <Row>
                                <Col xs="6">
                                    <h2>Programação</h2>
                                    <p>HTML</p>
                                    <p>CSS</p>
                                    <p>JavaScript</p>
                                    <p>React.js</p>
                                    <p>React Native</p>
                                    <p>jQuery</p>
                                    <p>Three.js</p>
                                    <p>Node.js</p>
                                    <p>PHP</p>
                                    <p>MySQL</p>
                                    <p>BootStrap</p>
                                    <p>Wordpress</p>
                                    <p>Git</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row>
                        {/* Software */}
                        <Col xs="6" className="colComp" id="soft">
                            <Row>
                                <Col xs="6">
                                    <h2>Software</h2>
                                    <p>Adobe Xd</p>
                                    <p>Adobe Illustrator</p>
                                    <p>Adobe InDesign</p>
                                    <p>Adobe Photoshop</p>
                                    <p>Adobe Photoshop Lightroom</p>
                                    <p>Adobe Premiere</p>
                                    <p>Adobe After Effects</p>
                                    <p>Adobe Animate</p>
                                    <p>3ds Max</p>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}
