import React from 'react';
import um from './imgs/ligacao1.png';
import aviao from './imgs/icones-12.png';
import eu from './imgs/sobre.png';
import mala from './imgs/icones-13.png';

export default class Sobre extends React.Component {
    render() {
        return (
            <div id="primeira">
                <img src={um} id="ligacaoUm" />
                
                <img src={aviao} id="aviao" />

                <h1 id="sobre">Sobre mim</h1>
                <p>Sofia Ribeiro é estudante do Mestrado de Comunicação Multimédia, no ramo de Multimédia Interativo, na Universidade de Aveiro. Gosta de desenhar e programar websites e aplicações com interfaces apelativas, acessíveis e criativas.
Integrar uma equipa criativa, como programadora e web designer de excelência, é o seu interesse no futuro.</p>

                <img src={eu} id="foto"/>
                
                <img src={mala} id="mala" />

                <h1 id="exp">Experiência</h1>
            </div>
        )
    }
}
