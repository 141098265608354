import React from 'react';
import { Row, Col, Modal, ModalHeader, ModalBody } from 'reactstrap';
import sete from './imgs/ligacao7.png';
import p1 from './imgs/projetos/proj-01.jpg';
import p2 from './imgs/projetos/proj-02.jpg';
import p3 from './imgs/projetos/proj-03.jpg';
import p4 from './imgs/projetos/proj-04.jpg';
import p5 from './imgs/projetos/proj-05.jpg';
import p6 from './imgs/projetos/proj-06.jpg';
import p7 from './imgs/projetos/proj-07.jpg';
import p8 from './imgs/projetos/proj-08.jpg';
import p9 from './imgs/projetos/proj-09.jpg';
import p10 from './imgs/projetos/proj-10.jpg';
import p11 from './imgs/projetos/proj-11.jpg';
import p12 from './imgs/projetos/proj-12.jpg';
import p13 from './imgs/projetos/proj-13.jpg';
import p14 from './imgs/projetos/proj-14.jpg';
import m1 from './imgs/projetos/modal/modal1.jpg';
import m2 from './imgs/projetos/modal/modal2.png';
import m3 from './imgs/projetos/modal/modal3.png';
import m4 from './imgs/projetos/modal/modal4.jpg';
import m5 from './imgs/projetos/modal/modal5.jpg';
import m6 from './imgs/projetos/modal/modal6.png';

export default class Projetos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal1: false,
            modal2: false,
            modal3: false,
            modal4: false,
            modal5: false,
            modal6: false,
            width: window.innerWidth
        };
    }

    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }

    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    toggle1 = () => {
        this.setState(prevState => ({
            modal1: !prevState.modal1
        }));
    }

    toggle2 = () => {
        this.setState(prevState => ({
            modal2: !prevState.modal2
        }));
    }

    toggle3 = () => {
        this.setState(prevState => ({
            modal3: !prevState.modal3
        }));
    }

    toggle4 = () => {
        this.setState(prevState => ({
            modal4: !prevState.modal4
        }));
    }

    toggle5 = () => {
        this.setState(prevState => ({
            modal5: !prevState.modal5
        }));
    }

    toggle6 = () => {
        this.setState(prevState => ({
            modal6: !prevState.modal6
        }));
    }

    render() {
        const { width } = this.state;
        const isMobile = width >= 425;

        const externalCloseBtn = <button className="close modalBtn" onClick={() => this.toggle1()}>&times;</button>;
        const externalCloseBtn2 = <button className="close modalBtn" onClick={() => this.toggle2()}>&times;</button>;
        const externalCloseBtn3 = <button className="close modalBtn" onClick={() => this.toggle3()}>&times;</button>;
        const externalCloseBtn4 = <button className="close modalBtn" onClick={() => this.toggle4()}>&times;</button>;
        const externalCloseBtn5 = <button className="close modalBtn" onClick={() => this.toggle5()}>&times;</button>;
        const externalCloseBtn6 = <button className="close modalBtn" onClick={() => this.toggle6()}>&times;</button>;

        if (isMobile) {
            return (
                <div id="setima">
                    <img src={sete} id="ligacaoSete" />
                    <h1>Projetos</h1>

                    <Row id="projetos">
                        <Col id="cell">
                            <img src={p1} />
                            <a onClick={this.toggle1}>
                                <div id="hoverp1">
                                    <h2>Cell Phone Symphony</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal1} toggle={this.toggle1} className={this.props.className} external={externalCloseBtn}>
                            <ModalHeader className="tit">Cell Phone Symphony</ModalHeader>
                            <ModalBody className="pmodal">
                                Com o intuito de promover um evento hipotético denominado Cell Phone Symphony, criei um conceito visual que adaptei a um cartaz e a uma brochura que seria para estar disponível no dia do evento.
                            </ModalBody>
                            <img src={m1} className="modalImg" />
                        </Modal>

                        <Col id="nos">
                            <img src={p2} />
                            <a onClick={this.toggle2}>
                                <div id="hoverp2">
                                    <h2>NOS Primavera Sound</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal2} toggle={this.toggle2} className={this.props.className} external={externalCloseBtn2}>
                            <ModalHeader className="tit">NOS Primavera Sound</ModalHeader>
                            <ModalBody className="pmodal">
                                Este website foi realizado no âmbito da Prova de Aptidão Artística realizada no final do meu 12º ano. Consistiu na criação de um conceito, design e implementação do website do festival Nos Primavera Sound.
                            </ModalBody>
                            <img src={m2} className="modalMockups" />
                        </Modal>

                        <Col id="ifeel">
                            <img src={p3} />
                            <a onClick={this.toggle3}>
                                <div id="hoverp3">
                                    <h2>I feel Porto</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal3} toggle={this.toggle3} className={this.props.className} external={externalCloseBtn3}>
                            <ModalHeader className="tit">I feel Porto</ModalHeader>
                            <ModalBody className="pmodal">
                                Este website foi realizado no âmbito da Unidade Curricular de Laboratório Multimédia 2, da Licenciatura de Novas Tecnologias da Comunicação.
        O seu público-alvo são todos os turistas que não têm tempo para planear o seu percurso para visitar a cidade do Porto.
                            </ModalBody>
                            <img src={m3} className="modalMockups" />
                        </Modal>
                    </Row>

                    <Row id="projetos">
                        <Col id="hagrid">
                            <img src={p4} />
                            <a onClick={this.toggle4}>
                                <div id="hoverp4">
                                    <h2>Hagrid's House</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal4} toggle={this.toggle4} className={this.props.className} external={externalCloseBtn4}>
                            <ModalHeader className="tit">Hagrid's House</ModalHeader>
                            <ModalBody className="pmodal">
                                No âmbito da Unidade Curricular de Imagem Digital Estática, da Licenciatura de Novas Tecnologias da Comunicação, o grupo de trabalho modelou um plano de jogo com as características da casa da personagem Hagrid dos filmes de Harry Potter. Os objetos foram desenhados com linhas rústicas e com as dimensões adequadas às dimensões da personagem.
                            </ModalBody>
                            <img src={m4} className="modalImg" />
                        </Modal>

                        <Col id="vieira">
                            <img src={p5} />
                            <a onClick={this.toggle5}>
                                <div id="hoverp5">
                                    <h2>Vieira de castro</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal5} toggle={this.toggle5} className={this.props.className} external={externalCloseBtn5}>
                            <ModalHeader className="tit">Vieira de Castro</ModalHeader>
                            <ModalBody className="pmodal">
                                A marca Vieira de Castro lançou um desafio aos alunos da Unidade Curricular de Empreendedorismo, da Licenciatura de Novas Tecnologias da Comunicação, de resolver a Omnicanalidade da marca. Em resposta ao desafio, foram propostas várias soluções: a renovação da imagem de marca, promoção de passatempos, interação nas redes sociais e a sua conexão com o website da marca, parcerias com outras marcas e um slogan.
                            </ModalBody>
                            <img src={m5} className="modalImg" />
                        </Modal>

                        <Col id="bio">
                            <img src={p6} />
                            <a onClick={this.toggle6}>
                                <div id="hoverp6">
                                    <h2>Bio Farm</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal6} toggle={this.toggle6} className={this.props.className} external={externalCloseBtn6}>
                            <ModalHeader className="tit">Bio Farm</ModalHeader>
                            <ModalBody className="pmodal">
                                O jogo BioFarm foi realizado no âmbito da Unidade Curricular de Laboratório Multimédia 3, da Licenciatura de Novas Tecnologias da Comunicação, em parceria com a Associação BioLiving.
        Tem como objetivo fazer o jogador compreender todo o processo do crescimento de uma árvore, desde a sua plantação, passando pelo processo de rega e o tratamento em estufa caso seja necessário.
                            </ModalBody>
                            <img src={m6} className="modalMockups" />
                        </Modal>
                    </Row>

                    <Row id="projetos">
                        <Col id="filters">
                            <img src={p7} />
                            <div id="hoverp7">
                                <h2>The Filters of Science</h2>
                            </div>
                        </Col>

                        <Col id="wash">
                            <img src={p8} />
                            <div id="hoverp8">
                                <h2>washClub</h2>
                            </div>
                        </Col>

                        <Col id="ntc">
                            <img src={p9} />
                            <div id="hoverp9">
                                <h2>25 anos ntc</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row id="projetos">
                        <Col id="footour">
                            <img src={p10} />
                            <div id="hoverp10">
                                <h2>Footour</h2>
                            </div>
                        </Col>

                        <Col id="copek">
                            <img src={p11} />
                            <div id="hoverp11">
                                <h2>Copek Design</h2>
                            </div>
                        </Col>

                        <Col id="blunder">
                            <img src={p12} />
                            <div id="hoverp12">
                                <h2>blunder music</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row id="projetos">
                        <Col id="ubi">
                            <img src={p13} />
                            <div id="hoverp13">
                                <h2>The simulator</h2>
                            </div>
                        </Col>

                        <Col id="makers">
                            <img src={p14} />
                            <div id="hoverp14">
                                <h2>Music Makers</h2>
                            </div>
                        </Col>

                        <Col>
                            <div id="branca"></div>
                        </Col>
                    </Row>
                </div>
            )
        } else {
            return (
                <div id="setima">
                    <img src={sete} id="ligacaoSete" />
                    <h1>Projetos</h1>

                    <Row id="projetos">
                        <Col id="cell">
                            <img src={p1} />
                            <a onClick={this.toggle1}>
                                <div id="hoverp1">
                                    <h2>Cell Phone Symphony</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal1} toggle={this.toggle1} className={this.props.className} external={externalCloseBtn}>
                            <ModalHeader className="tit">Cell Phone Symphony</ModalHeader>
                            <ModalBody className="pmodal">
                                Com o intuito de promover um evento hipotético denominado Cell Phone Symphony, criei um conceito visual que adaptei a um cartaz e a uma brochura que seria para estar disponível no dia do evento.
                            </ModalBody>
                            <img src={m1} className="modalImg" />
                        </Modal>

                        <Col id="nos">
                            <img src={p2} />
                            <a onClick={this.toggle2}>
                                <div id="hoverp2">
                                    <h2>NOS Primavera Sound</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal2} toggle={this.toggle2} className={this.props.className} external={externalCloseBtn2}>
                            <ModalHeader className="tit">NOS Primavera Sound</ModalHeader>
                            <ModalBody className="pmodal">
                                Este website foi realizado no âmbito da Prova de Aptidão Artística realizada no final do meu 12º ano. Consistiu na criação de um conceito, design e implementação do website do festival Nos Primavera Sound.
                            </ModalBody>
                            <img src={m2} className="modalMockups" />
                        </Modal>
                    </Row>

                    <Row id="projetos">
                        <Col id="ifeel">
                            <img src={p3} />
                            <a onClick={this.toggle3}>
                                <div id="hoverp3">
                                    <h2>I feel Porto</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal3} toggle={this.toggle3} className={this.props.className} external={externalCloseBtn3}>
                            <ModalHeader className="tit">I feel Porto</ModalHeader>
                            <ModalBody className="pmodal">
                                Este website foi realizado no âmbito da Unidade Curricular de Laboratório Multimédia 2, da Licenciatura de Novas Tecnologias da Comunicação.
        O seu público-alvo são todos os turistas que não têm tempo para planear o seu percurso para visitar a cidade do Porto.
                            </ModalBody>
                            <img src={m3} className="modalMockups" />
                        </Modal>

                        <Col id="hagrid">
                            <img src={p4} />
                            <a onClick={this.toggle4}>
                                <div id="hoverp4">
                                    <h2>Hagrid's House</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal4} toggle={this.toggle4} className={this.props.className} external={externalCloseBtn4}>
                            <ModalHeader className="tit">Hagrid's House</ModalHeader>
                            <ModalBody className="pmodal">
                                No âmbito da Unidade Curricular de Imagem Digital Estática, da Licenciatura de Novas Tecnologias da Comunicação, o grupo de trabalho modelou um plano de jogo com as características da casa da personagem Hagrid dos filmes de Harry Potter. Os objetos foram desenhados com linhas rústicas e com as dimensões adequadas às dimensões da personagem.
                            </ModalBody>
                            <img src={m4} className="modalImg" />
                        </Modal>
                    </Row>

                    <Row id="projetos">
                        <Col id="vieira">
                            <img src={p5} />
                            <a onClick={this.toggle5}>
                                <div id="hoverp5">
                                    <h2>Vieira de castro</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal5} toggle={this.toggle5} className={this.props.className} external={externalCloseBtn5}>
                            <ModalHeader className="tit">Vieira de Castro</ModalHeader>
                            <ModalBody className="pmodal">
                                A marca Vieira de Castro lançou um desafio aos alunos da Unidade Curricular de Empreendedorismo, da Licenciatura de Novas Tecnologias da Comunicação, de resolver a Omnicanalidade da marca. Em resposta ao desafio, foram propostas várias soluções: a renovação da imagem de marca, promoção de passatempos, interação nas redes sociais e a sua conexão com o website da marca, parcerias com outras marcas e um slogan.
                            </ModalBody>
                            <img src={m5} className="modalImg" />
                        </Modal>

                        <Col id="bio">
                            <img src={p6} />
                            <a onClick={this.toggle6}>
                                <div id="hoverp6">
                                    <h2>Bio Farm</h2>
                                </div>
                            </a>
                        </Col>

                        <Modal isOpen={this.state.modal6} toggle={this.toggle6} className={this.props.className} external={externalCloseBtn6}>
                            <ModalHeader className="tit">Bio Farm</ModalHeader>
                            <ModalBody className="pmodal">
                                O jogo BioFarm foi realizado no âmbito da Unidade Curricular de Laboratório Multimédia 3, da Licenciatura de Novas Tecnologias da Comunicação, em parceria com a Associação BioLiving.
        Tem como objetivo fazer o jogador compreender todo o processo do crescimento de uma árvore, desde a sua plantação, passando pelo processo de rega e o tratamento em estufa caso seja necessário.
                            </ModalBody>
                            <img src={m6} className="modalMockups" />
                        </Modal>
                    </Row>

                    <Row id="projetos">
                        <Col id="filters">
                            <img src={p7} />
                            <div id="hoverp7">
                                <h2>The Filters of Science</h2>
                            </div>
                        </Col>

                        <Col id="wash">
                            <img src={p8} />
                            <div id="hoverp8">
                                <h2>washClub</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row id="projetos">
                        <Col id="ntc">
                            <img src={p9} />
                            <div id="hoverp9">
                                <h2>25 anos ntc</h2>
                            </div>
                        </Col>
                        <Col id="footour">
                            <img src={p10} />
                            <div id="hoverp10">
                                <h2>Footour</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row id="projetos">
                        <Col id="copek">
                            <img src={p11} />
                            <div id="hoverp11">
                                <h2>Copek Design</h2>
                            </div>
                        </Col>

                        <Col id="blunder">
                            <img src={p12} />
                            <div id="hoverp12">
                                <h2>blunder music</h2>
                            </div>
                        </Col>
                    </Row>

                    <Row id="projetos">
                        <Col id="ubi">
                            <img src={p13} />
                            <div id="hoverp13">
                                <h2>The simulator</h2>
                            </div>
                        </Col>

                        <Col id="makers">
                            <img src={p14} />
                            <div id="hoverp14">
                                <h2>Music Makers</h2>
                            </div>
                        </Col>
                    </Row>
                </div>
            )
        }
    }
}